import "./QrTicket.scss";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axiosInstance from "./axios";
import { Ticket } from "./Ticket";
import LanguageDropdown from "./LanguageDropdown/LanguageDropdown";
import i18next from "i18next";

const QrTicket = () => {
  const { t } = useTranslation("common");

  const [data, setData] = useState<Ticket>();
  const [isModalShown, setIsModalShown] = useState(false);

  const handleShowModal = () => {
    setIsModalShown(true);
  };

  const handleCloseModal = () => {
    setIsModalShown(false);
  };

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `ticket${window.location.pathname}`
      );
      setData(response.data);
    } catch {
      alert(t("fetch_error"));
    }
  };

  const changeLanguageHandler = () => {
    if (data) {
      i18next.changeLanguage(data?.language);
    }
  };

  const handleUseTicket = async () => {
    if (data?.id) {
      try {
        await axiosInstance.put(`ticket/${data.id}/use`);
        handleCloseModal();
        setData(undefined);
      } catch {
        alert(t("use_error"));
      } finally {
        fetchData();
      }
    }
  };

  console.log(data);

  useEffect(() => {
    fetchData();
    changeLanguageHandler();
  }, []);

  return (
    <div className="berg-pages-qr-ticket">
      <header>
        {data?.merchantId === "meander" && (
          <a href="https://www.meanderoravice.sk">
            <img
              width="135px"
              height="38px"
              src={require("./assets/logo_meander_color.svg").default}
              alt="logo"
            />
          </a>
        )}
        {data?.merchantId === "bramawgorce" && (
          <a href="https://www.bramawgorce.com/">
            <img src={require("./assets/logo_gorce.png")} alt="logo" />
          </a>
        )}
        <LanguageDropdown />
      </header>
      {!data ? (
        <main />
      ) : (
        <main>
          <div className="berg-pages-qr-ticket__qr-code">
            <img src={data.qrCode} alt="qr-code" />
          </div>
          <div className="berg-pages-qr-ticket__details">
            <span>
              <b>{data.name}</b>
            </span>
            <br />
            <b>ID: {data.id}</b>
            <br />
            <b>{`${t("dates")}${formatDate(data.validFrom)} - ${formatDate(
              data.validTo
            )}`}</b>
            {data.additionalData.map((addData) => (
              <div className="berg-pages-qr-ticket__additional-data">
                <span>{addData.question}: </span>
                <span>{addData.answer}</span>
              </div>
            ))}
          </div>
          <div
            className="berg-pages-qr-ticket__validity"
            aria-invalid={!data.valid}
          >
            {data.valid ? (
              <div>
                <img
                  src={require("./assets/green-tick.svg").default}
                  alt="green tick"
                />
                <b>{t("valid")}</b>
              </div>
            ) : (
              <>
                <div>
                  <img
                    src={require("./assets/red-cross.svg").default}
                    alt="red cross"
                  />
                  <b>{t("invalid")}</b>
                </div>
                <div>{data.causeOfInvalidity}</div>
              </>
            )}
          </div>
          <div className="berg-pages-qr-ticket__price">
            {t("price")}
            <b>{`${data.price.amount} ${data.price.currency}`}</b>
          </div>
          <button
            onClick={handleShowModal}
            className="berg-pages-qr-ticket__use-button"
            aria-hidden={!data.valid}
          >
            {t("use")}
          </button>
          {/*<div className="berg-pages-qr-ticket__apps">*/}
          {/*  <img*/}
          {/*    src={require("./assets/google-wallet.svg").default}*/}
          {/*    alt="Google Wallet"*/}
          {/*  />*/}
          {/*  <img*/}
          {/*    src={require("./assets/apple-wallet.svg").default}*/}
          {/*    alt="Apple Wallet"*/}
          {/*  />*/}
          {/*</div>*/}
        </main>
      )}
      <footer>
        {data?.merchantId === "meander" && (
          <Trans
            i18nKey="footer_meander"
            ns="common"
            components={{
              a: <a href="mailto:recepcia@meanderoravice.sk">.</a>,
            }}
          />
        )}
        {data?.merchantId === "bramawgorce" && (
          <Trans
            i18nKey="footer_brama"
            ns="common"
            components={{
              a: <a href="mailto:biuro@bramagorce.com">.</a>,
            }}
          />
        )}
      </footer>
      <Modal
        isShow={isModalShown}
        handleClose={handleCloseModal}
        handleConfirm={handleUseTicket}
      />
    </div>
  );
};

const Modal = ({
  isShow,
  handleClose,
  handleConfirm,
}: {
  isShow: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}) => {
  const { t } = useTranslation();

  if (!isShow) {
    return null;
  }

  return (
    <div className="berg-pages-qr-ticket__modal">
      <img src={require("./assets/tick.svg").default} alt="tick" />
      <strong>{t("modal_title")}</strong>
      <p>{t("modal_content")}</p>
      <button aria-label="confirm" onClick={handleConfirm}>
        {t("modal_confirm")}
      </button>
      <button aria-label="cancel" onClick={handleClose}>
        {t("modal_cancel")}
      </button>
    </div>
  );
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day}.${month}.${year}`;
};

export default QrTicket;
